/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

#fullscreen-container {
  width: 100vw;   /* 100% of the viewport width */
  height: 100vh;  /* 100% of the viewport height */
  position: fixed; /* Makes it cover the entire window */
  top: 0;
  left: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-cell {
  width: 60px; 
  height: 60px;
}

.data-cell {
  padding: 10px;
  padding-left: 0px;
  border: 1px solid black;
}

.full-size-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}