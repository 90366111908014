.location-button {
    margin: 0px 5px;
    padding: 0px 5px;
    width: 90%;
    border-radius: 0px;
    border: 1px solid black;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0);
}

.location-button:hover {
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(100, 0, 253);
}

.accordion-button {
    background-color: rgb(0, 0, 0) !important;
    color: rgb(255, 255, 255) !important;
    border: 1px solid rgba(0, 0, 0, 0);
}

.accordion-body {
background-color: #030000;
color: #ffffff;
}

.data-cell {
padding: 10px;
border: 1px solid #ddd;
}

.data-cell-right {
    padding: 0px;
    margin: 0px;
    border: 0px solid #ddd;
    text-align: right;
}


/* Style for WebKit-based browsers (Chrome, Safari) */
.scrollable-panel::-webkit-scrollbar {
width: 8px;
}

.scrollable-panel::-webkit-scrollbar-track {
background: #000000;
border-radius: 10px;
}

.scrollable-panel::-webkit-scrollbar-thumb {
background: #000000;
border-radius: 10px;
}

.scrollable-panel::-webkit-scrollbar-thumb:hover {
background: #000000;
}

/* Style for Firefox */
.scrollable-panel {
scrollbar-width: thin;
scrollbar-color: #ffffff #000000;
}

.input-container {
    display: flex;
    gap: 10px; /* Optional: Adds space between the inputs */
  }